import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { HomeComponent } from "./components/home/home.component";
import { ResumeComponent } from './components/resume/resume.component';
import { ImprintComponent } from './components/imprint/imprint.component';

@NgModule({
  declarations: [AppComponent, FooterComponent, HeaderComponent, HomeComponent, ResumeComponent, ImprintComponent],
  imports: [BrowserModule, AppRoutingModule],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
