import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
  age: number;

  constructor(private titleService: Title) {
    this.titleService.setTitle("Home");
  }

  ngOnInit() {
    this.calculateAge();
  }

  calculateAge() {
    const today = new Date();
    const birthDate = new Date(1996, 5, 21);
    this.age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      this.age--;
    }
  }
}
